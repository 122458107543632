import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { NuxtPage } from '@nuxt/schema';
import { localStorageGet, localStorageRemove } from '@mop/shared/utils/localStorage';
import { customerModel } from '@/models';
import type { CustomerModelRef, UpdateEmailPayload, UpdatePasswordPayload } from '@/types/customer';
import type {
  BffApiBonusVoucherStepsGetResponse,
  BffApiAccountMeDeleteRequest,
  BffCustomerRegisterPayload,
  BffCustomerUpdateAttributes,
  BffSupportMailPostRequest,
} from '@/types/bff';

type LoadingState = {
  handleAddresses: boolean;
  handleCustomer: boolean;
  isEmailAlreadyRegistered: boolean;
  updatePassword: boolean;
  updateCustomerData: boolean;
  updateEmail: boolean;
  updateAddress: boolean;
  deleteCustomer: boolean;
  registerCustomer: boolean;
  createAddress: boolean;
  createSupportEmail: boolean;
  bonusVoucherSteps: boolean;
  resetPassword: boolean;
  login: boolean;
  loading: boolean;
};

type CustomerComposableStorage = {
  customer: CustomerModelRef;
};

export function useMopCustomer() {
  const storage = initStorage<CustomerComposableStorage>('useCustomer');
  const customerModelRef = storage.get('customer') || storage.saveAndGet('customer', ref(customerModel(null)));
  const bonusVoucherStepsRef = ref<BffApiBonusVoucherStepsGetResponse | null>(null);
  const loadingRef: Ref<LoadingState> = ref({
    handleCustomer: false,
    handleAddresses: false,
    isEmailAlreadyRegistered: false,
    updatePassword: false,
    updateCustomerData: false,
    updateEmail: false,
    updateAddress: false,
    deleteCustomer: false,
    registerCustomer: false,
    createAddress: false,
    createSupportEmail: false,
    bonusVoucherSteps: false,
    resetPassword: false,
    login: false,
    loading: computed(() => isLoading(loadingRef)),
  });

  const { $apiBff, $cookie, $config, $mopI18n, $mopConfig } = useNuxtApp();

  async function handleCustomer() {
    loadingRef.value.handleCustomer = true;

    if ($cookie.get(constants.COOKIE.SESSION_TYPE) === constants.SESSION_TYPES.SESSION_CUSTOMER) {
      const result = await $apiBff.getMe();
      const bffStatus = result.data?.code;
      if (bffStatus === 'OK') {
        customerModelRef.value = customerModel(result);
      }
    } else {
      customerModelRef.value = customerModel(null);
    }
    loadingRef.value.handleCustomer = false;
  }

  async function updateCustomerData(payload: BffCustomerUpdateAttributes) {
    loadingRef.value.updateCustomerData = true;
    const result = await $apiBff.updateCustomerData(payload);
    const bffStatus = result.data?.code;
    if (bffStatus === 'OK') {
      customerModelRef.value = customerModel(result);
    }
    loadingRef.value.updateCustomerData = false;

    return bffStatus;
  }

  async function isEmailAlreadyRegistered(email: string): Promise<boolean> {
    loadingRef.value.isEmailAlreadyRegistered = true;
    await createAnonymousSessionIfNotSet();
    const response = await $apiBff.registerEmailCheck(email);
    loadingRef.value.isEmailAlreadyRegistered = false;

    if (response.data?.code === 'REGISTER_EMAIL_ALREADY_EXISTS') {
      return true;
    }
    return false;
  }

  async function updatePassword(payload: UpdatePasswordPayload): Promise<boolean> {
    loadingRef.value.updatePassword = true;
    const response = await $apiBff.updatePassword(payload);

    if (response.data?.code === 'OK' && response.data?.session?.accessToken) {
      loadingRef.value.updatePassword = false;
      return true;
    }

    loadingRef.value.updatePassword = false;
    return false;
  }

  async function updateEmail(payload: UpdateEmailPayload) {
    loadingRef.value.updateEmail = true;
    const result = await $apiBff.updateCustomerData({
      email: payload.newEmail,
    });
    const bffStatus = result.data?.code;

    if (bffStatus === 'OK') {
      customerModelRef.value = customerModel(result);
    }

    loadingRef.value.updateEmail = false;
    return bffStatus;
  }

  async function deleteCustomer(payload: BffApiAccountMeDeleteRequest) {
    loadingRef.value.deleteCustomer = true;

    const result = await $apiBff.deleteCustomer(payload);
    const bffStatus = result.data?.code;
    loadingRef.value.deleteCustomer = false;
    return bffStatus;
  }

  async function registerCustomer(payload: BffCustomerRegisterPayload, skipAddressCheck = false) {
    loadingRef.value.registerCustomer = true;
    await createAnonymousSessionIfNotSet();
    const result = await $apiBff.register(payload, skipAddressCheck);
    const bffStatus = result.data?.code;
    loadingRef.value.registerCustomer = false;
    return bffStatus;
  }

  async function passwordResetToken(email: string) {
    loadingRef.value.resetPassword = true;
    await createAnonymousSessionIfNotSet();
    const result = await $apiBff.passwordResetToken({ email, locale: $mopI18n.locale });
    const bffStatus = result.data?.code;
    loadingRef.value.resetPassword = false;
    return bffStatus;
  }

  async function passwordReset(token: string, newPassword: string) {
    loadingRef.value.resetPassword = true;
    await createAnonymousSessionIfNotSet();
    const result = await $apiBff.passwordReset({ token, newPassword });
    const bffStatus = result.data?.code;
    if (bffStatus === 'OK' && result.data?.session?.accessToken) {
      const redirectUrl = localStorageGet(constants.LOCAL_STORAGE.AFTER_AUTH_REDIRECT);
      if (redirectUrl) {
        localStorageRemove(constants.LOCAL_STORAGE.AFTER_AUTH_REDIRECT);
        window.location.href = redirectUrl + '?passwordReset=1';
      } else {
        window.location.reload();
      }
    } else {
      loadingRef.value.resetPassword = false;
    }
    return bffStatus;
  }

  async function createSupportEmail(params: FormData) {
    loadingRef.value.createSupportEmail = true;
    const result = await $apiBff.createSupportEmail(params as any as BffSupportMailPostRequest);
    loadingRef.value.createSupportEmail = false;
    return result.data?.code;
  }

  async function getBonusVoucherSteps() {
    loadingRef.value.bonusVoucherSteps = true;
    const result = await $apiBff.getBonusVoucherSteps();
    const bffStatus = result.data?.code;
    bonusVoucherStepsRef.value = result?.data || null;
    loadingRef.value.bonusVoucherSteps = false;
    return bffStatus;
  }

  function getAccountRoutes(accountRoutes: NuxtPage[] = []): NuxtPage[] {
    const isLoyaltyEnabled: boolean = $mopConfig?.isLoyaltyProgramEnabled() === true;
    return accountRoutes.filter((route) => {
      return !route.meta?.loyaltyCountryOnly || (route.meta?.loyaltyCountryOnly === true && isLoyaltyEnabled);
    });
  }

  async function login(params: { email: string; password: string }) {
    loadingRef.value.login = true;
    await createAnonymousSessionIfNotSet();
    const result = await $apiBff.login(params);
    const bffStatus = result.data?.code;
    if (bffStatus === 'OK' && result.data?.session && result.data?.session.accessToken) {
      await handleCustomer();
      let redirectUrl = localStorageGet(constants.LOCAL_STORAGE.AFTER_AUTH_REDIRECT);
      if (customerModelRef.value.isEmployeeAccount()) {
        redirectUrl = `/${URLS.ORDER_ON_BEHALF}`;
      }
      if (redirectUrl) {
        localStorageRemove(constants.LOCAL_STORAGE.AFTER_AUTH_REDIRECT);
        window.location.href = redirectUrl;
      } else {
        window.location.reload();
      }
    } else {
      loadingRef.value.login = false;
    }
    return bffStatus;
  }

  async function loginOnBehalf(customerId: string) {
    loadingRef.value.login = true;
    const result = await $apiBff.loginOnBehalf(customerId);
    if (result.data?.code !== 'OK') {
      loadingRef.value.login = false;
      return false;
    }
    window.location.reload();
    return true;
  }

  async function logout(params?: { redirectUrl?: string; skipReload?: boolean }) {
    let redirectUrl = customerModelRef.value.isLoginOnBehalf() ? `/${URLS.ORDER_ON_BEHALF}` : $config.public.HOME_URL;
    if (params?.redirectUrl) {
      redirectUrl = params.redirectUrl;
    }

    customerModelRef.value = customerModel(null);
    const result = await $apiBff.logout();
    const bffStatus = result.data?.code;
    if (bffStatus === 'OK') {
      if (!params?.skipReload) {
        window.location.href = redirectUrl;
      }
    } else {
      // TODO: commercetools show error message
    }
  }

  async function createAnonymousSessionIfNotSet() {
    if (!isSessionCreated()) {
      await $apiBff.createAnonymousSession();
    }
  }

  function isSessionCreated(): boolean {
    // SESSION_TYPE is set by backend
    return Boolean($cookie.get(constants.COOKIE.SESSION_TYPE));
  }

  return securedWrap({
    customerModelRef,
    bonusVoucherStepsRef,
    loadingRef,
    handleCustomer,
    isEmailAlreadyRegistered,
    updatePassword,
    updateEmail,
    updateCustomerData,
    deleteCustomer,
    registerCustomer,
    passwordResetToken,
    passwordReset,
    createSupportEmail,
    getBonusVoucherSteps,
    getAccountRoutes,
    login,
    loginOnBehalf,
    logout,
    createAnonymousSessionIfNotSet,
    isSessionCreated,
  });
}
