import type { ProductProjection } from '@commercetools/platform-sdk';
import { productModel } from './productModel';
import type { ProductModel } from '@/types/product';
import type { ApiResponse } from '@/types/apiInit';
import type { BffApiWishlistGet } from '@/types/bff';

export function wishlistModel(responseData: ApiResponse<BffApiWishlistGet> | null) {
  const response = responseData ?? {};
  const productIds: string[] = [];

  // performance optimization for faster product discovery
  if (response.data?.wishlist?.lineItems?.length) {
    response.data.wishlist.lineItems.forEach((lineItem) => {
      productIds.push(lineItem.productId);
    });
  }

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    getCount(): number {
      return this.getProducts().length;
    },

    isProductInWishlist(productId: string): boolean {
      return productIds.includes(productId);
    },

    getProducts(): ProductModel[] {
      return (
        response.data?.wishlist?.lineItems
          .filter((item) => item.custom?.fields.productProjection)
          .map((item) =>
            productModel({
              statusCode: 200,
              body: item.custom?.fields.productProjection as ProductProjection,
            }),
          ) ?? []
      );
    },
  };
}
