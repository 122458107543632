<script setup lang="ts">
import type { ObserveScrollConfig, ActionPosition, Timer } from '@mop/types';
import type { Ref, WatchStopHandle } from 'vue';
import SVGMopLogo from '@mop/shared/images/logo/logo_mop.svg?component';
import SVGMopDenimLogo from '@mop/shared/images/logo/denim_logo_mop.svg?component';
import SVGSearch from '@mop/shared/images/misc/search.svg?component';
import SVGHeart from '@mop/shared/images/misc/header-heart.svg?component';
import SVGBag from '@mop/shared/images/misc/bag.svg?component';
import SVGPerson from '@mop/shared/images/misc/header-person.svg?component';
import SVGService from '@mop/shared/images/misc/header-service.svg?component';
import SVGBurger from '@mop/shared/images/misc/burger.svg?component';
import SVGClose from '@mop/shared/images/misc/header-close.svg?component';
import { localStorageGet, localStorageSet } from '@mop/shared/utils/localStorage';
import type { OverlayGroup } from '@/types/overlay';

defineOptions({
  name: 'MopHeader',
});

const { $breakpoint, $scroll, $gtm2, $urls, $mopI18n, $mopConfig } = useNuxtApp();
const isMobileViewportRef = computed(
  () => $breakpoint.isTinyRef.value || $breakpoint.isSmallRef.value || $breakpoint.isMediumRef.value,
);
const { activeCategoryPathRef } = useMopRouter();
const route = useRoute();
const router = useRouter();
const searchOverlayGroup: OverlayGroup = 'header';
const headerOverlay = useMopOverlay();
const headerSearchOverlay = useMopOverlay(searchOverlayGroup);
const headerNavigationOverlay = useMopOverlay();
const { cartModelRef } = useMopCartClient();
const { wishlistModelRef } = useMopWishlistClient();
const { customerModelRef } = useMopCustomer();
const { openCartOverlay, componentName: cartComponentName } = useMopCartOverlayClient();
const { searchStoreByGln, storeModelRef } = useMopStoreFinder();
const componentNames = {
  mobileNavigation: 'MopHeaderMobileMenuOverlay',
  navigation: 'MopHeaderNavigationOverlay',
  search: 'MopHeaderSearchSuggestionsOverlay',
  service: 'MopHeaderServiceOverlay',
  account: 'MopHeaderAccountOverlay',
  cart: cartComponentName,
};
const isDenimRef = computed(
  () => route.path.includes('mopd') || activeCategoryPathRef.value?.[0]?.getMopId() === MOP_CATEGORY_IDS.DENIM,
);
const hasOpenOverlayRef = ref(false);
const isSearchSuggestionOpenRef = ref(false);
const isAccountIconBadgeVisibleRef = ref(false);
const openOverlayComponentNameRef = computed(() => {
  return headerOverlay.activeOverlayRef.value?.isOpen ? headerOverlay.activeOverlayRef.value.componentName : '';
});
const isHeaderDisabledRef = computed(() => {
  return (
    openOverlayComponentNameRef.value !== '' &&
    ![componentNames.service, componentNames.account].includes(openOverlayComponentNameRef.value)
  );
});
const isMobileNavigationOpenRef = ref(false);
const whiteBackgroundOverlays = [componentNames.service, componentNames.account, componentNames.cart];

const headerTopRef: Ref<HTMLElement | null> = ref(null);
const headerWrapper: Ref<HTMLElement | null> = ref(null);
let isHeaderOverlayClosableByClick = false;
let serviceOverlayTimer: Timer;
let accountOverlayTimer: Timer;
const OVERLAY_OPEN_DELAY = 200;
let stopGuestBuyerBadgeWatcher: WatchStopHandle;

function initAccountIconBadge() {
  isAccountIconBadgeVisibleRef.value =
    route.name !== 'account-register' &&
    $mopConfig.isGuestBuyerMarketingEnabled() &&
    !localStorageGet(constants.LOCAL_STORAGE.HIDE_GUEST_BUYER_ACCOUNT_BADGE) &&
    customerModelRef.value.isGuest();

  stopGuestBuyerBadgeWatcher = watch(
    () => route.name,
    (name) => {
      if (name !== 'account-register') {
        return;
      }
      isAccountIconBadgeVisibleRef.value = false;
      localStorageSet(constants.LOCAL_STORAGE.HIDE_GUEST_BUYER_ACCOUNT_BADGE, '1');
      stopGuestBuyerBadgeWatcher();
    },
    { immediate: true },
  );
}

async function toggleMobileNavigationOverlay() {
  if (isMobileNavigationOpenRef.value) {
    return await closeHeaderOverlays();
  }

  await headerOverlay.open({
    type: 'left',
    componentName: componentNames?.mobileNavigation,
    overrideParams: {
      showClose: false,
    },
    props: {
      offsetTop: headerTopRef.value?.getBoundingClientRect().bottom,
    },
    onOpen: () => {
      isMobileNavigationOpenRef.value = !isMobileNavigationOpenRef.value;
    },
    onClose: () => {
      isMobileNavigationOpenRef.value = false;
    },
  });
}

let stopScrollWatch: WatchStopHandle;

watch(isMobileViewportRef, async () => {
  await closeHeaderOverlays();
});
async function openHeaderOverlay(overlayName: string) {
  await headerOverlay.open({
    type: 'top',
    componentName: overlayName,
    overrideParams: {
      closeOnMouseLeave: true,
      lockScroll: false,
      forceRender: false,
    },
    onOpen: () => {
      hasOpenOverlayRef.value = true;
      stopScrollWatch = watch($scroll.offsetTopRef, async () => {
        // close account and service overlays on scroll
        await closeHeaderOverlays();
      });
      setTimeout(() => {
        // Needed to avoid immidate close after open when moussenter and click occurs simultaniously
        isHeaderOverlayClosableByClick = true;
      }, 100);
    },
    onClose: () => {
      isHeaderOverlayClosableByClick = false;
      hasOpenOverlayRef.value = false;
      stopScrollWatch();
    },
  });
}

function toggleHeaderServiceOverlay(event: Event) {
  const isDisabled: boolean = isHeaderDisabledRef.value && event.type !== 'click';
  if (isDisabled) {
    return;
  }
  cancelToggleHeaderServiceOverlay();
  serviceOverlayTimer = setTimeout(async () => {
    const isDisabled: boolean = isHeaderDisabledRef.value && event.type !== 'click';
    if (isDisabled) {
      return;
    }
    if (
      isHeaderOverlayClosableByClick &&
      event.type === 'click' &&
      openOverlayComponentNameRef.value === componentNames.service
    ) {
      await closeHeaderOverlays();
      return;
    }
    handleEventTrack('service');
    await openHeaderOverlay(componentNames.service);
  }, OVERLAY_OPEN_DELAY);
}

function cancelToggleHeaderServiceOverlay() {
  clearTimeout(serviceOverlayTimer);
}

function isActiveButton(componentName: string) {
  return openOverlayComponentNameRef.value === componentName;
}

function isWhiteBackgroundOverlay() {
  return whiteBackgroundOverlays.includes(openOverlayComponentNameRef.value);
}

async function toggleHeaderAccountOverlay(event: Event) {
  const isDisabled: boolean = isHeaderDisabledRef.value && event.type !== 'click';
  if (isDisabled) {
    return;
  }
  if (customerModelRef.value.isEmployeeAccount()) {
    if (event.type === 'click') {
      await router.push($mopI18n.localePath($urls.ORDER_ON_BEHALF));
    }
    return;
  }
  if (customerModelRef.value.isGuest()) {
    await closeHeaderOverlays();
    if (event.type === 'click') {
      await router.push($mopI18n.localePath($urls.ACCOUNT));
    }
    return;
  }
  cancelToggleHeaderAccountOverlay();
  accountOverlayTimer = setTimeout(async () => {
    if (
      isHeaderOverlayClosableByClick &&
      event.type === 'click' &&
      openOverlayComponentNameRef.value === componentNames.account
    ) {
      await closeHeaderOverlays();
      return;
    }
    handleEventTrack('myAccount');
    await openHeaderOverlay('MopHeaderAccountOverlay');
  }, OVERLAY_OPEN_DELAY);
}

async function openNavigationOverlay() {
  await headerNavigationOverlay.open({
    type: 'top',
    componentName: componentNames.navigation,
    componentKey: 'navigation',
  });
}

function cancelToggleHeaderAccountOverlay() {
  clearTimeout(accountOverlayTimer);
}

async function handleCartClick() {
  if (openOverlayComponentNameRef.value === componentNames.cart) {
    await closeHeaderOverlays();
    return;
  }
  // cart overlay should not open in cart page
  if (route.name === $urls.CART.substr(1)) {
    return;
  }

  // Open cart only if empty
  if (cartModelRef.value.getCount()) {
    // Redirect to cart
    await router.push($mopI18n.localePath($urls.CART));
    return;
  }
  handleEventTrack('cart');
  return openCartOverlay();
}

async function closeHeaderOverlays(ignoreTouch = false) {
  if (ignoreTouch && (isTouchDevice() || isHeaderDisabledRef.value)) {
    return;
  }
  await headerOverlay.closeAll();
}

async function handleWrapperClicked(event: Event, ignoreTouch: boolean) {
  if (isHeaderDisabledRef.value) {
    return;
  }
  if (ignoreTouch && isTouchDevice()) {
    return;
  }
  if (event.target === headerWrapper.value) {
    await closeHeaderOverlays();
  }
}

async function handleSearchClick() {
  if (isSearchSuggestionOpenRef.value) {
    await headerSearchOverlay.closeAll();
    isSearchSuggestionOpenRef.value = false;
    return;
  }
  await headerSearchOverlay.open({
    type: 'top',
    componentName: componentNames.search,
    overrideParams: {
      showClose: false,
      forceRender: true,
      lockScroll: '.search-suggest__scroll',
    },
    onOpen: () => {
      handleEventTrack('search');
      isSearchSuggestionOpenRef.value = true;
      $gtm2.reportLegacyEngagement({
        event: 'site_search_start',
        category: $gtm2.getPageCategoryId(),
        label: 'start',
        // @ts-ignore
        value: document.location.origin + document.location.pathname,
      });
    },
    onClose: () => {
      isSearchSuggestionOpenRef.value = false;
    },
  });
}

const headerColorChangeConfig: ObserveScrollConfig = {
  calculateActionPositions: () => {
    const orientingEl = document.getElementsByClassName('footer')?.[0] as HTMLElement;
    if (!orientingEl) {
      return [];
    }
    const baseStartPosition = orientingEl.offsetTop;

    const actionColorPosition: ActionPosition = {
      onEnter: () => {},
      positionStart: baseStartPosition,
    };

    return [actionColorPosition];
  },
  throttleDelay: 50,
};

onMounted(async () => {
  await initNavigationOverlay();
  initAccountIconBadge();

  if (customerModelRef.value.isEmployeeAccount()) {
    await searchStoreByGln(customerModelRef.value.getEployeeStoreGln());
  }
});

async function initNavigationOverlay() {
  await headerNavigationOverlay.init({
    componentName: componentNames.navigation,
    componentKey: 'navigation',
    type: 'top',
    overrideParams: {
      closeOnMouseLeave: true,
      lockScroll: true,
      mouseLeaveTimeout: 500,
      showClose: false,
    },
    onOpen: () => {
      handleEventTrack('navigation');
      hasOpenOverlayRef.value = true;
    },
  });
}

function handleEventTrack(label: string) {
  $gtm2.reportLegacyEngagement({
    event: 'headerIconClick',
    category: $gtm2.getCurrentPage() || String(route.name) || 'N/A',
    label,
  });
}
</script>

<template>
  <header
    ref="headerTopRef"
    v-observe-scroll="headerColorChangeConfig"
    :class="[
      'header',
      {
        'header--inactive': openOverlayComponentNameRef,
        'header--hidden': $route.meta && $route.meta.hideHeader,
        'header__icons--has-open-searchoverlay': isSearchSuggestionOpenRef,
      },
    ]"
  >
    <div
      id="toast-placeholder"
      ref="headerWrapper"
      class="header__wrapper lock-scroll-padding"
      @mouseenter="handleWrapperClicked($event, true)"
      @click="handleWrapperClicked($event, false)"
    >
      <div class="header__navigation" @mouseenter="closeHeaderOverlays(true)">
        <MopHeaderMainNavigation class="desktop-only" @open="openNavigationOverlay" />

        <button
          :class="[
            'header__mobile-navbar-button header__icon mobile-only',
            {
              'header-active': isActiveButton(componentNames?.mobileNavigation),
            },
          ]"
          @click.prevent="toggleMobileNavigationOverlay"
        >
          <SVGClose v-show="isMobileNavigationOpenRef" class="header__icon-close-svg" width="30" height="19" />
          <SVGBurger v-show="!isMobileNavigationOpenRef" width="30" height="19" />
        </button>
        <button
          :class="[
            'header__icon mobile-only',
            {
              'header-active': isActiveButton(componentNames.search),
            },
          ]"
          @click="handleSearchClick"
          @mouseenter="closeHeaderOverlays(true)"
        >
          <SVGClose v-show="isSearchSuggestionOpenRef" width="30" height="19" />
          <SVGSearch v-show="!isSearchSuggestionOpenRef" class="header__icon-svg" width="30" height="19" />
        </button>
      </div>
      <div class="header__logo lock-scroll-padding" data-cy="header-logo">
        <ClientOnly>
          <NuxtLink
            key="client"
            :title="$mopI18n.t('common.brandName')"
            class="header__logo-link"
            :to="$config.public.HOME_URL"
            no-prefetch
          >
            <SVGMopDenimLogo v-if="isDenimRef" class="header__logo-image" width="190" height="40" />
            <SVGMopLogo v-else class="header__logo-image" width="190" height="40" />
          </NuxtLink>
          <template #fallback>
            <!-- Using only locale for SSR url, client takes over and adds gender -->
            <NuxtLink
              key="server"
              :title="$mopI18n.t('common.brandName')"
              class="header__logo-link"
              :to="$mopI18n.localePath('')"
              no-prefetch
            >
              <SVGMopDenimLogo v-if="isDenimRef" class="header__logo-image" width="190" height="40" />
              <SVGMopLogo v-else class="header__logo-image" width="190" height="40" />
            </NuxtLink>
          </template>
        </ClientOnly>
      </div>

      <div
        :class="[
          'header__icons',
          {
            'header__icons--black': isWhiteBackgroundOverlay(),
          },
        ]"
      >
        <Ui2Button
          v-if="customerModelRef.isEmployeeAccount()"
          :to="$mopI18n.localePath($urls.ORDER_ON_BEHALF)"
          size="sm"
          class="header__order-on-behalf-employee"
          appearance="primary"
          type="outline"
          icon-start="storefront"
          :label="`${isMobileViewportRef ? '' : 'Store Nr. '}${storeModelRef?.getFuturaNo() ?? ''}`"
        >
        </Ui2Button>
        <Ui2Button
          v-if="customerModelRef.isLoginOnBehalf()"
          :to="$mopI18n.localePath($urls.ORDER_ON_BEHALF)"
          size="sm"
          class="header__order-on-behalf-customer"
          appearance="primary"
          type="outline"
          icon-start="user"
          :label="isMobileViewportRef ? customerModelRef.getLastName() : customerModelRef.getFullName()"
        >
        </Ui2Button>

        <button
          :class="[
            'header__icon desktop-only',
            {
              'header-active': isActiveButton(componentNames.search),
            },
          ]"
          @click="handleSearchClick"
          @mouseenter="closeHeaderOverlays(true)"
        >
          <SVGClose v-show="isSearchSuggestionOpenRef" width="30" height="19" />
          <SVGSearch v-show="!isSearchSuggestionOpenRef" class="header__icon-svg" width="30" height="19" />
        </button>
        <button
          v-if="!customerModelRef.isEmployeeAccount() && !customerModelRef.isLoginOnBehalf()"
          :class="[
            'header__icon desktop-only',
            {
              'header-active': isActiveButton(componentNames.service),
            },
          ]"
          @click.stop.prevent="toggleHeaderServiceOverlay"
          @mouseenter.stop.prevent="($event) => !isTouchDevice() && toggleHeaderServiceOverlay($event)"
          @mouseleave="($event) => !isTouchDevice() && cancelToggleHeaderServiceOverlay()"
        >
          <SVGService class="header__icon-svg" width="30" height="19" />
        </button>
        <button
          v-if="!customerModelRef.isEmployeeAccount()"
          :class="[
            'header__icon desktop-only',
            {
              'header-active': isActiveButton(componentNames.account),
            },
          ]"
          @click.stop.prevent="toggleHeaderAccountOverlay"
          @mouseenter="($event) => !isTouchDevice() && toggleHeaderAccountOverlay($event)"
          @mouseleave="($event) => !isTouchDevice() && cancelToggleHeaderAccountOverlay()"
        >
          <SVGPerson class="header__icon-svg" width="30" height="19" />
          <Ui2DotBadge v-if="isAccountIconBadgeVisibleRef" state="error" class="header__icon-badge" />
        </button>
        <NuxtLink
          v-if="!customerModelRef.isEmployeeAccount() && !customerModelRef.isLoginOnBehalf()"
          class="header__icon header__icon--wishlist"
          :to="$mopI18n.localePath($urls.WISHLIST)"
          :data-items="wishlistModelRef.getCount() || ''"
          no-prefetch
          @click="() => handleEventTrack('wishlist')"
          @mouseenter="() => closeHeaderOverlays()"
        >
          <SVGHeart class="header__icon-svg" width="30" height="19" />
        </NuxtLink>
        <button
          :class="[
            'header__icon',
            'header__icon--cart',
            {
              'header-active': isActiveButton(componentNames.cart),
            },
          ]"
          :data-items="cartModelRef.getCount() || ''"
          @click="handleCartClick"
          @mouseenter="closeHeaderOverlays(true)"
        >
          <SVGBag class="header__icon-svg" width="30" height="19" />
        </button>
      </div>
    </div>
    <LazyMopOverlayManager :group="searchOverlayGroup" />
  </header>
</template>

<style lang="scss" scoped>
.header {
  @include z(layers, above-layer);

  position: sticky;
  top: 0;
  display: block;
  width: 100%;
  background: $white;
  transition: all 0.2s ease;
}

.header--hidden {
  display: none;
}

.header__wrapper {
  display: flex;
  height: $header-height;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  @include apply-upto(medium) {
    height: $mobile-header-height;
    align-items: center;
  }
}

.header__icon-badge {
  position: absolute;
  top: 10px;
  right: 10px;
}

.header__logo {
  position: absolute;
  margin: 0 auto;
  top: 18px;
  left: 50%;
  transform: translateX(-50%);

  @include apply-upto(medium) {
    top: 12px;
  }
}

.header__navigation {
  padding-left: $global-padding;
  height: 100%;

  @include apply-upto(medium) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header__icons--has-open-searchoverlay {
  :deep(.main-navigation__children) {
    display: none;
  }
}

.header__logo-link {
  display: block;
  width: 190px;
  height: 40px;

  @include apply-upto(medium) {
    width: 119px;
    height: 25px;
  }
}

.header__icons {
  display: flex;
  align-items: center;
  padding-right: $space5;
  padding-top: 12px;

  @include apply-upto(medium) {
    padding-top: 0;
  }
}

.header__icons--black {
  --color: #{$black};
}

.header__icon {
  @include exponent-text-style(-3px, 22px);
  @include link-neutral($space10);

  border: 0;
  line-height: 0;
  background: transparent;
  cursor: pointer;
  width: $header-icon-width;
  overflow: hidden;
}

.header__icon-svg {
  @include apply-upto(medium) {
    width: 35px;
    height: 22px;
  }
}

.header__icon--cart {
  width: $mobile-cart-icon-width;

  @include apply-upto(medium) {
    &::after {
      top: 4px;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 0 1px;
      margin: 0;
    }
  }
}

.header__icon--wishlist {
  @include apply-upto(medium) {
    &::after {
      display: none;
    }

    .header__icon-svg {
      width: 30px;
      height: 19px;
    }
  }
}

.header__icon:hover {
  transform: scale(1.2);
  transition: all 0.2s ease;

  @include apply-upto(medium) {
    transform: none;
  }
}

.desktop-only {
  @include apply-upto(medium) {
    display: none;
  }
}

.mobile-only {
  @include apply-from(large) {
    display: none;
  }
}

.header__logo-image {
  width: inherit;
  height: inherit;
}

.header--inactive {
  .header__icon,
  .header__item {
    opacity: $disabled-opacity;
  }

  .header-active {
    opacity: 1;
  }
}

.header__mobile-navbar-button {
  width: auto;
  margin-left: -$global-padding;
  padding-left: $global-padding;
  padding-right: $space10;
}
</style>
